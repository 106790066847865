import * as THREE from 'three';
import {IUniform} from "three/src/renderers/shaders/UniformsLib";

import $ from 'jquery';

export default abstract class Scene {
    private readonly canvas: HTMLCanvasElement;
    private readonly renderer: THREE.WebGLRenderer;
    private lastRenderTime: number = Date.now();

    constructor(canvas: HTMLCanvasElement) {
        this.canvas = canvas;
        this.renderer = new THREE.WebGLRenderer({canvas: this.canvas, antialias: true});
        const bgColor = $("body").css("background-color");
        this.renderer.setClearColor(bgColor);

        this.setupScene();
        this.handleResize();

        requestAnimationFrame(() => this.renderFrame());
    }

    public getSize() {
        return new THREE.Vector2(this.canvas.clientWidth, this.canvas.clientHeight);
    }

    protected abstract setupScene(): void;

    protected handleResize(): void {
    }

    protected handleRenderFrame(renderer: THREE.Renderer, deltaTime: number): void {
    }

    private renderFrameInternal() {
        if (this.canvas.width !== this.canvas.clientWidth || this.canvas.height !== this.canvas.clientHeight) {
            this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight, false);
            this.handleResize();
        }

        //const now = Date.now();
        //const dt = (now - this.lastRenderTime) / 1000;
        //this.lastRenderTime = now;

        const dt = 0.05

        this.handleRenderFrame(this.renderer, dt);

        //requestAnimationFrame(() => this.renderFrame());
    }

    public renderFrame() {
        requestAnimationFrame(() => this.renderFrameInternal());
    }
}

export abstract class FullscreenQuadScene extends Scene {
    private camera: THREE.Camera;
    private scene: THREE.Scene;
    private material: THREE.ShaderMaterial;

    private uniforms : { [uniform: string]: IUniform };

    protected abstract getVertexShader(): string;

    protected abstract getFragmentShader(): string;

    public getMaterial() {
        return this.material;
    }

    protected setupScene() {
        const size = this.getSize();

        this.scene = new THREE.Scene();
        //this.camera = new THREE.PerspectiveCamera(45, size.y / size.x, 1, 1000);
        this.camera = new THREE.OrthographicCamera(-1, 1, -1, 1, 0, 150);

        this.uniforms  = {
            resolution: {
                value: new THREE.Vector2(0, 0)
            },
            time: {
                value: 0
            }
        }

        this.material = new THREE.ShaderMaterial({
            uniforms: this.uniforms,
            vertexShader: this.getVertexShader(),
            fragmentShader: this.getFragmentShader(),
            depthWrite: false,
            depthTest: false,
        });

        const quad = new THREE.Mesh(
            new THREE.PlaneGeometry(2,2),
            this.material
        );

      //  this.camera.position.z = 200;

        this.scene.add(quad);
    }

    protected handleResize() {
        const size = this.getSize();
        //this.camera.aspect = size.y / size.x;
        //this.camera.updateProjectionMatrix();
        this.uniforms.resolution.value = size;
    }

    protected handleRenderFrame(renderer: THREE.Renderer, deltaTime: number) {
        this.uniforms.time.value += deltaTime;

        renderer.render(this.scene, this.camera);
    }
}