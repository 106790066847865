import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css' // Import precompiled Bootstrap css
import '@fortawesome/fontawesome-free/css/all.css'

import './style.scss'

import fragmentShaderSrc from './scene.frag.glsl';
import vertexShaderSrc from './scene.vert.glsl';

import {FullscreenQuadScene} from './scene';

import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

class MyScene extends FullscreenQuadScene {
    protected getFragmentShader(): string {
        return fragmentShaderSrc;
    }

    protected getVertexShader(): string {
        return vertexShaderSrc;
    }

}

$(() => {
    const el = $("#background").get(0);
    const scene = new MyScene(<HTMLCanvasElement>el);
    AOS.init();

    $(document).on('scroll', () => {
        scene.renderFrame();
    });
});